<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          color="info"
          dark
          small
          label
          class="mr-1"
        >
          {{ formattedItem.unidades }} {{ 'unidad'|pluralize(parseFloat(formattedItem.unidades), 'es') }}
        </v-chip>
        <v-chip
          v-if="faltaIndicarNserieEnMaterial"
          color="error"
          dark
          small
          label
          class="mr-1"
        >
          Nº de serie
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import filters from '@/utils/filters'


export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      faltaIndicarNserieEnMaterial: false,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.subsis.descripcion
      item.subtitle = item.subsis.codigo
      item.avatarColor = 'info'
      item.unidades = filters.decimal(item.cant_materiales)
      return item
    }
  },
  async created () {
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.faltaIndicarNserieEnMaterial = await this.$offline.parteTrabajo.faltaIndicarNserieEnMaterial(
        this.item.subsis.idsubsis, this.item.lparte_trabajo.idparte_trabajo
      )
    }
  }
}
</script>